const caseStudies = [
    {
        title: "Comcast Smart Solutions",
        path: "/work/comcast-smart-solutions",
        block: {
            description: 'This public-facing website educates users about Comcast’s smart solutions. It is designed to optimize ongoing updates through a content management system.',
            buttontext: 'Read More',
            image: '/css-site.jpg',
        },
        page: [
            {
                type: "hero",
                data: {
                    title: "Comcast Smart Solutions",
                    img: "/css-video.jpg",
                    img_height: "250px"
                }
            },
            {
                type: "richtext",
                data: {
                    backbtn: true,
                    title: "Website development",
                    subtitle: "MOD Worldwide",
                    body: [
                        "<b>Comcast</b> needed a <b>public-facing</b> website to showcase how its smart technology solutions drive efficiency and enhance experiences across buildings, analytics, mobility, digital signage, and smart lighting. This site empowers communities and businesses to discover how Comcast can help create smarter, more connected environments.",
                        "As the <b>lead developer</b> on this project, I built the site using <b>React, Next.js, SCSS, Framer, and Directus as the CMS</b>. Working closely with a designer, I developed an intuitive interface that makes it easy for users to navigate Comcast’s smart solutions. I continue to maintain and update the site to ensure it grows alongside Comcast’s evolving technologies and customer needs."
                    ],
                    media: {
                        type: "image",
                        src: "/css-laptop.png",
                        alt: "Comcast Smart Solutions website"
                    },
                    button: {
                        url: "https://www.xfinity.com/smartsolutions/",
                        target: "_blank",
                        button_copy: "View Website"
                    }
                }
            }
        ]
    },
    {
        title: "Xfinity interactive map&nbsp;game",
        path: "/work/interactive-map-game",
        block: {
            description: 'This interactive expereince was created to educate employees about Xfinity’s coverage of the 2024 Olympic and Paralympic Games in Paris.',
            buttontext: 'Read More',
            image: '/map-cover.jpg',
        },
        page: [
            {
                type: "hero",
                data: {
                    title: "Xfinity interactive map&nbsp;game",
                    img: "/game-map.jpg",
                    img_height: "250px"
                }
            },
            {
                type: "richtext",
                data: {
                    backbtn: true,
                    title: "Game development: Olympic tour&nbsp;of&nbsp;Paris",
                    subtitle: "MOD Worldwide",
                    body: [
                        "I led the development of an Interactive Olympic Map experience to highlight Xfinity’s coverage of the <b>2024 Paris Olympic and Paralympic Games</b>. Built with <b>React, Next.js, React Three Fiber, Drei, Tailwind, and Framer Motion</b>, the project delivered an engaging, guided experience that highlighted key moments from the Games.",
                        "The interactive map featured seamless animations and immersive visuals, effectively combining multiple tools to deliver a delightful experience. This game has had over 14k users."
                    ],
                    media: {
                        type: "image",
                        src: "/laptop.png",
                        alt: "Interactive map game"
                    },
                    button: {
                        url: "https://xfinity-paris-tour.xchangemissions.com/",
                        target: "_blank",
                        button_copy: "Play Game"
                    }
                }
            }
        ]
    },
    {
        title: "Mercer Bucks Pickleball Club",
        path: "/work/mercer-bucks-pickleball-club",
        block: {
            description: 'Since 2021, I’ve partnered with Mercer Bucks Pickleball Club (MBPC) to build their brand identity and launch a custom WordPress website that connects with their community of over 1,500 members. This site serves as a hub for players to reserve courts, book lessons, and stay up to date on events.',
            buttontext: 'Read More',
            image: '/mbpc-cover.jpg',
        },
        page: [
            {
                type: "hero",
                data: {
                    title: "Mercer Bucks Pickleball Club",
                    img: "/mbpc-hero.jpg",
                    img_height: "250px"
                }
            },
            {
                type: "richtext",
                data: {
                    backbtn: true,
                    title: "Website development and brand design",
                    subtitle: "MG Design",
                    body: [
                        "In 2021, <b>Mercer Bucks Pickleball Club</b> (MBPC) reached out to me with a vision of creating a welcoming, memorable online presence for their new club. They needed cohesive <b>branding</b> and an engaging <b>website</b> that would appeal to all pickleball players—from beginners to pros—while also providing practical features for membership, lessons, and events."
                    ],
                    media: {
                        type: "image",
                        src: "/mbpc-laptop.png",
                        alt: "Mercer Bucks Pickleball Club website"
                    },
                    button: {
                        url: "https://www.playmbpc.com/",
                        target: "_blank",
                        button_copy: "View Website"
                    }
                }
            },
            {
                type: "richtext",
                data: {
                    body: [
                        "MBPC was preparing to open their doors by early 2022, which set a tight timeline for creating a recognizable brand and launching the website in time for membership signups by November 2021. I developed a logo and refined a color palette that felt true to MBPC’s goals, working through several design iterations to capture their ideal look and feel. With the branding in place, I created a welcoming WordPress site that matched MBPC's style, allowing them to share information on memberships, court reservations, and lessons. The WordPress platform also made it easy for MBPC to manage updates themselves.",
                        "Since launch, MBPC’s website and branding have helped foster a thriving member community, and I continue to support them with new graphics and site updates. The branding we created has become a core part of their identity and appears on everything from club merchandise to promotional materials, strengthening MBPC’s presence and making them recognizable throughout the community."
                    ]
                }
            }
        ]
    },
    {
        title: "Comcast Business Brand&nbsp;Hub",
        path: "/work/comcast-business-brandhub",
        block: {
            description: 'I led the development and launch of the Comcast Business resource website under an accelerated three-week timeline. This website serves as the central hub for all Comcast Business brand messaging, visual identity guidelines, and creative assets.',
            buttontext: 'Read More',
            image: '/cb-cover.jpg',
        },
        page: [
            {
                type: "hero",
                data: {
                    title: "Comcast Business Brand&nbsp;Hub",
                    img: "/cb-hero.jpg",
                    img_height: "250px"
                }
            },
            {
                type: "richtext",
                data: {
                    backbtn: true,
                    title: "Website Development",
                    subtitle: "MOD Worldwide",
                    body: [
                        "I stepped in to lead the development of a brand resource website for Comcast Business with just three weeks until launch. The project required swift action — content was scattered, the site map was incomplete, and final design approvals were pending. I quickly brought structure to the project, aligning stakeholders and developing a clear launch plan. I collaborated with and delegated tasks to teammates while assuming a significant portion of the development work myself. Our team worked tirelessly to deliver a polished 30-page website on time.",
                    ],
                    media: {
                        type: "image",
                        src: "/cb-laptop.png",
                        alt: "Interactive map game"
                    }
                }
            },
            {
                type: "richtext",
                data: {
                    body: [
                        "Working alongside another developer, I took ownership of key development tasks while coordinating efforts across the team to ensure we met our deadline. Together, we delivered a polished 30-page website, built with <b>Next.js, React, Tailwind, Framer Motion, and Directus as the CMS.</b>",
                        "The site serves as a central hub for Comcast Business brand messaging, visual identity guidelines, and creative assets. Its password-protected asset library allows the Comcast Business team to easily browse and download materials without relying on external platforms. The client was thrilled with the outcome, and our team continues to support and expand the platform as Comcast Business brand needs evolve."
                    ]
                }
            }
        ]
    },
    {
        title: "Xfinity learning games",
        path: "/work/learning-games",
        background_color: '#101010',
        block: {
            description: 'Discover a range of interactive learning games that make mastering key concepts entertaining and memorable. From quizzes to mindfulness activities, each game offers a fresh, engaging experience designed to blend fun with focused learning.',
            buttontext: 'Read More',
            image: '/games-cover.jpg',
        },
        page: [
            {
                type: "hero",
                data: {
                    title: "Xfinity learning games",
                    img: "/games-hero.jpg",
                    img_height: "250px"
                }
            },
            {
                type: "richtext",
                data: {
                    backbtn: true,
                    title: "Game development",
                    subtitle: "MOD Worldwide",
                    body: [
                        "I led the development of this collection of <b>interactive learning games</b>. Using <b>Next.js, React, Tailwind, and Framer Motion</b> I transformed essential information into immersive, playful experiences. Each game is crafted with a unique style and focus, making it easy—and enjoyable—for players to learn while they play.",
                        "<b>Posthog</b> is used for analytics on all games. These games have over 10k users, providing valuable data about employee engagement with the game as well as understanding of the subject matter."
                    ],
                }
            },
            {
                type: "grid",
                data: {
                    center: true,
                    items: [
                        {
                            title: "NOW Mindfulness Game",
                            copy: 'This game combines mindfulness with learning. Players answer questions about <b>NOW Internet and Mobile</b> while navigating calming activities. Correct answers keep the experience smooth, while wrong answers add a bit of “distraction” to the Zen. This is the most played game I have created, with over 19k users.',
                            buttontext: 'Play game',
                            image: '/scrnshot-now.jpg',
                            url: 'https://now-launch.xchangemissions.com/'
                        },
                        {
                            title: "Xfinity Fake Facts",
                            copy: 'Spot the fake! In this game, players are given three statements about <b>Xfinity Internet</b>, but only two are true. Choose the fake fact before the timer runs out and sharpen your knowledge along the&nbsp;way.',
                            buttontext: 'Play game',
                            image: '/scrnshot-cards.jpg',
                            url: 'https://xfinity-fake-facts.themoderati.com/'
                        },
                        {
                            title: "Are You As Connected As The Spokes Generation",
                            copy: 'A fast-paced quiz show that brings Xfinity employees up to speed on the capabilities of the <b>10G network</b>. This game blends trivia with a bit of friendly competition, making it fun to learn the ins and outs of ultra-fast internet.',
                            buttontext: 'Play game',
                            image: '/scrnshot-trivia.jpg',
                            url: 'https://10g-game.xchangemissions.com/'
                        },
                        {
                            title: "Puss in Boots",
                            copy: 'Join Puss as he races Wolf to reach the Wishing Star in this board game adventure. Players answer <b>Xfinity Rewards</b> trivia questions to help Puss advance, making this game a blend of chance, strategy, and fun&nbsp;facts.',
                            buttontext: 'Play game',
                            image: '/scrnshot-puss.jpg',
                            url: 'https://puss-in-boots.xchangemissions.com/'
                        },
                        {
                            title: "Xfinity Trolls Karaoke",
                            copy: 'Help BroZone get ready for their show by filling in the missing lyrics in their songs, each one featuring a fun fact about <b>Xfinity Rewards</b>. Hit the right notes to complete the song and move on to the next verse. Rock&nbsp;on!',
                            buttontext: 'Play game',
                            image: '/scrnshot-trolls.jpg',
                            url: 'https://trolls.xchangemissions.com/'
                        },
                        {
                            title: "Xfinity Scene Stream",
                            copy: 'Are you a streaming expert? In Scene Stream, players watch clips from popular shows on Xfinity and identify where they come from. Test your knowledge and see how well you know <b>Xfinity’s streaming catalog</b> before time runs&nbsp;out!',
                            buttontext: 'Play game',
                            image: '/scrnshot-stream.jpg',
                            url: 'https://scenestream.xchangemissions.com/'
                        },
                    ],
                }
            }
        ]
    },
    {
        title: "Metlife Self-care Quiz",
        path: "/work/metlife-quiz",
        block: {
            description: 'This digital quiz was created to educate Metlife plan members about self care. This engaging interactive experience features self-care resources and a meditation.',
            buttontext: 'Read More',
            image: '/metlife-cover.jpg',
            position: "center"
        },
        page: [
            {
                type: "hero",
                data: {
                    title: "Metlife Self-care Quiz",
                    img: "/metlife-hero.jpg",
                    img_height: "250px",
                }
            },
            {
                type: "richtext",
                data: {
                    backbtn: true,
                    title: "Game development",
                    subtitle: "MOD Worldwide",
                    body: [
                        "<b>Metlife</b> partnered with MOD to create an interactive learning game focused on self-care, designed to educate and engage users. I led the development of this project, completing it within an accelerated four-week timeline.",
                        "Using <b>Next.js, React, Tailwind, and Framer Motion</b>, I built a seamless and visually captivating experience that blends education with interactivity. The game features links to self-care resources, highlighted throughout the quiz questions, and concludes with a meditation offering optional music to encourage relaxation. I crafted animations that brought the content to life, enhancing user engagement and reinforcing key learning points. The result was a polished, intuitive game that aligned with the client’s vision and goals."
                    ],
                    media: {
                        type: "image",
                        src: "/metlife-laptop.png",
                        alt: "Interactive map game"
                    },
                    button: {
                        url: "https://metlifequiz.aetna.com/",
                        target: "_blank",
                        button_copy: "Take Quiz"
                    }
                }
            }
        ]
    },
    {
        title: "Xfinity Brand Hub",
        path: "/work/xfinity-brand-hub",
        block: {
            description: 'The Xfinity Brand Hub is the ultimate resource for all things Xfinity, providing up-to-date, consistent brand messaging and visuals. I help keep the Brand Hub aligned with Xfinity’s evolving identity, from minor updates to larger branding transformations.',
            buttontext: 'Read More',
            image: '/bh-cover.jpg',
        },
        page: [
            {
                type: "hero",
                data: {
                    title: "Xfinity Brand Hub",
                    img: "/bh-hero.jpg",
                    img_height: "250px"
                }
            },
            {
                type: "richtext",
                data: {
                    backbtn: true,
                    title: "Website maintenance",
                    subtitle: "MOD Worldwide",
                    body: [
                        "As Xfinity expands, the <b>Xfinity Brand Hub</b> remains a centralized source of consistent and <b>comprehensive brand information</b>, covering everything from messaging to visual identity. This digital destination ensures brand alignment, offering teams easy access to the latest standards and guidelines.",
                        "I support the Brand Hub’s upkeep and evolution, handling both minor updates and extensive updates during major rebrands. Using <b>React, Next.js, SCSS, Framer, and Directus as the CMS</b>, I’ve extensively updated the hub three times, incorporating three major brand launches and adapting the site’s design to match the brand’s growth. The Brand Hub remains a vital tool, providing clear, adaptable, and accessible brand guidance for Xfinity teams."
                    ],
                    media: {
                        type: "image",
                        src: "/brandhub-laptop.png",
                        alt: "Xfinity brand hub website"
                    },
                }
            }
        ]
    },
    {
        title: "Garret Cares",
        path: "/work/garret-cares",
        block: {
            description: 'Since 2021, I’ve partnered with Garret Cares, a nonprofit honoring Garret Christino, to build their brand and website. This includes logo design, color scheme, and a WordPress site with e-commerce functionality to support their mission of advancing education, health, and essential services for underserved communities.',
            buttontext: 'Read More',
            image: '/gc-cover.jpg',
        },
        page: [
            {
                type: "hero",
                data: {
                    title: "Garret Cares",
                    img: "/gc-hero.jpg",
                    img_height: "250px"
                }
            },
            {
                type: "richtext",
                data: {
                    backbtn: true,
                    title: "Website development and brand design",
                    subtitle: "MG Design",
                    body: [
                        "Since 2021, I’ve worked closely with <b>Garret Cares</b>, a nonprofit founded in memory of Garret Christino, a close friend of mine, to support its mission of “Reaching Out More.” I helped guide and craft their brand identity, starting with a custom logo that reflects the organization’s focus on Growth, Diversity, and Connection. Through a collaborative process, we developed multiple logo concepts, gathering feedback from family and friends, to produce a final design that captures Garret's legacy.","For their digital presence, I designed and continue to maintain the Garret Cares <b>WordPress</b> website, setting up hosting, loading content, and integrating an e-commerce plugin. This site allows Garret Cares to share their mission and raise funds through merchandise sales, supporting their core causes of education, health, and essentials for underserved communities."
                    ],
                    media: {
                        type: "image",
                        src: "/gc-laptop.png",
                        alt: "Garret Cares website",
                    },
                    button: {
                        url: "https://garretcares.org/",
                        target: "_blank",
                        button_copy: "View Website"
                    }
                }
            }
        ]
    },
    {
        title: "Orbital",
        path: "/work/orbital",
        block: {
            description: "Orbital is an interactive art installation created by a team of four as a senior thesis, inviting visitors to collaboratively build a unique solar system in real time. Using a custom web app, participants designed planets that were instantly added to a projected solar system, blending art and technology for a shared, immersive experience.",
            buttontext: "Read More",
            image: "/orbital-cover.jpg"
        },
        page: [
            {
                type: "hero",
                data: {
                    title: "Orbital",
                    img: "/orbital-hero.jpg",
                    img_height: "250px"
                }
            },
            {
                type: "richtext",
                data: {
                    backbtn: true,
                    title: "Interactive art installation",
                    subtitle: "Drexel User Experience and Interaction Design Senior Project 2022",
                    body: [
                        "Orbital was an <b>interactive art installation</b> that invited visitors to <b>collaboratively build a solar system in real time</b>. Over nine months, our senior thesis team designed, developed, and deployed this experience. I served as the UI lead, developer, and supported UX testing.",
                        "During the exhibit, visitors created unique planets using our <b>custom web app</b>, which allowed them to select from predefined planet types, customize attributes like size, terrain, and color. The experience featured a dynamic, <b>real-time solar system projection</b> where users’ creations appeared. Other features included a joystick station allowing visitors to cycle through the created planets, and a live statistics dashboard to display metrics throughout the event and highlight the most recently created planet.",
                        "We kept the planet builder live after the exhibit so people could still enjoy the experience. 'Add to galaxy' will no longer work since the solar system is no longer live."
                    ],
                    media: {
                        type: "video",
                        src: "https://www.youtube.com/embed/JaKvuOuIV5A?si=TnBtSnxpLdoE2dJ_",
                    },
                    button: {
                        url: "https://orbital-webapp.vercel.app/begin",
                        target: "_blank",
                        button_copy: "Build a Planet"
                    }
                }
            },
            {
                type: "richtext",
                data: {
                    body: [
                        "The basis for our project centered around this challenge: <b>How can we create an interactive art experience that excites, engages, and is memorable for our users?</b> We tackled this by splitting the work into three phases: Research, Design & Development, and Execution. Through interviews, user workshops, and prototype testing, we arrived at our final concept: An interactive solar system."
                    ]
                }
            },
            {
                type: "richtext",
                data: {
                    body: [
                        "The planet creation flow was central to our exhibit, and much of our research focused on that. Our goal for the web app was to create a process that was quick and easy to use and understand. We refined the user interface and process through research and user testing over six months. The final product was streamlined to take only a minute to complete with enough variability that each visitor could create a unique planet.",
                        "Our technical stack included <b>Next.js, React, and React Three Fiber</b>, enabling the creation of 3D planets with layered textures. The solar system’s real-time updates used SWR, while Tailwind streamlined UI development. The joystick interaction, using the Logitech Extreme 3D Pro Joystick, was created by reading the joystick inputs through the USB port with JavaScript."
                    ],
                    media: {
                        placement: "left",
                        type: "video",
                        src: "https://player.vimeo.com/video/1043556331?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
                    },
                }
            },
            {
                type: "richtext",
                data: {
                    body: [
                        "The event was held in the Westphal lobby at Drexel University. We designed a floor plan that optimized the space’s darkness for projection while ensuring visitor safety. Our setup was informed by research into our space and similar installations. We conducted multiple testing sessions and updated our layout and equipment configuration.",
                        "The large mission control screen, displayed on Westphal’s multi-screen system, drew visitors’ attention as they entered. An information table showcased facts about planet types, a scale model of planets, stickers, and treats. QR codes and informational flyers about the interactions and exhibit were strategically placed throughout the room. Each large screen displayed a different rear-projected view of the solar system, allowing visitors to approach without obstructing the view. The joystick station, where visitors scrolled through the planet database, was positioned between the screens and the information table."
                    ],
                    media: {
                        type: "image",
                        src: '/orbital-floorplan.png',
                        alt: 'Floor Plan'
                    },
                }
            },
            {
                type: "richtext",
                data: {
                    body: [
                        "Our goal was to create an interactive art exhibit that excited, intrigued, and was memorable. We achieved this by building an interactive experience that was visually stunning and easy to engage with. High user interest and engagement validated our success, as evidenced by the robust backend database filled with user-created planets. Each unique planet served as a personal expression of its creator, contributing to a diverse and collaborative solar system.",
                        "This project demonstrated the power of collaboration and user-focused design in creating an engaging and memorable experience. The unique planets and vibrant solar system we built together were a testament to the success of our vision. <b>This project is a MUSE Creative Awards 2022 winner</b>."
                    ]
                }
            }
        ]
    },
    {
        title: "Interactive Technology",
        path: "/work/interactive-technology",
        block: {
            description: 'Blending creativity with technology, these projects explore the intersection of interactive art and IoT-driven design. Each project pushed the boundaries of physical and digital experiences to bring ideas to life.',
            buttontext: 'Read More',
            image: '/mosaic-cover.png',
        },
        page: [
            {
                type: "hero",
                data: {
                    title: "Interactive Technology",
                    img: "/carduino.jpg",
                    img_height: "250px"
                }
            },
            {
                type: "richtext",
                data: {
                    backbtn: true,
                    title: "Microcontroller Projects",
                    subtitle: "Drexel University Interactive Digital Media",
                    body: [
                        "This collection presents two innovative interactive projects that demonstrate the fusion of technology and creativity in different domains, from interactive art installations to IoT robotics. Reactive Mosaic explores real-time visual transformation, while Carduino emphasizes sensor integration and remote motor control. Together, they demonstrate the versatility of microcontrollers and showcase the unique applications available to shaping both artistic and functional solutions."
                    ],
                }
            },
            {
                type: "grid",
                data: {
                    center: true,
                    outline: true,
                    items: [
                        {
                            title: "Reactive Mosaic ",
                            copy: 'An interactive art installation that transforms real-time camera input into a dynamic 20x20 monochrome mosaic. The system interprets live video into a grid of cubes, each reflecting pixel data through rotational shifts to display grayscale values. Designed to scale from a personal digital display to a large-scale physical installation powered by Raspberry Pi and motors, Reactive Mosaic bridges the gap between digital media and tactile art. Future iterations aim to introduce color variations and refine physical components for a fully realized kinetic display.',
                            video: '/reactive-mosaic-loop.mp4',
                        },
                        {
                            title: "Carduino",
                            copy: 'A remote-controlled IoT car powered by Arduino, blending mechanical engineering with interactive control systems. Carduino is driven via a custom dashboard interface, allowing users to steer, accelerate, and brake wirelessly over WiFi. Equipped with an ultrasonic sensor, the vehicle autonomously detects obstacles within a 20 cm range, stopping automatically to prevent collisions. The development process involved wiring motor controls, integrating sensors, and adaptive problem-solving. Built using Arduino IoT Cloud, Carduino highlights the potential of IoT in functional robotics.',
                            image: '/carduino-hero.jpg',
                        },
                    ],
                }
            }
        ]
    },
];

export default caseStudies;

