import Footer from "./Footer"
import Nav from "./Nav"
import Wrapper from "./Wrapper"
import { ScrollProvider } from "../context/ScrollContext"
import { useEffect } from "react"

export default function Page({children, ...props}) {
    const navPosition = props.navPosition || "sticky"

    return(
        <ScrollProvider threshold={50}>
            <Wrapper background={props.background}>
                <Nav navPosition={navPosition}/>
                    {children}
                <Footer/>
            </Wrapper>
        </ScrollProvider>
    )
}