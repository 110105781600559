import BlocksGrid from "../components/blocks/BlocksGrid";
import Page from "../components/blocks/Page";
import caseStudies from "../data/work";

export default function Work() {

    const blocksGridItems = caseStudies.map(work => ({
        title: work.title,
        copy: work.block.description,
        buttontext: work.block.buttontext,
        url: work.path,
        image: work.block.image,
        position: work.block.position,
        target: 'self'
    }));

    return( 
        <Page>
             <BlocksGrid items={blocksGridItems} />
        </Page>
    )
}