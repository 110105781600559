import ArrowButton from "./ArrowButton"
import Section from "./Section"
export default function RichText(props){

    return(
    <Section className={`${props.media?.placement === 'left' ? "flex-col-reverse mdlg:flex-row-reverse" : "flex-col-reverse mdlg:flex-row"} gap-10 mt-5`}>
        <div className={`flex flex-col gap-4 w-full ${props.media ? 'mdlg:w-4/6' : 'mdlg:w-11/12'} pl-10`}>
            {props.backbtn &&
                <ArrowButton
                    url="/work"
                    copy="Back"
                />
            }
            {props.title &&
                <h2 className="standard-h2" dangerouslySetInnerHTML={{__html:props.title}}/>
            }
            {props.subtitle &&
                <h3 className="text-xs -mt-1 mb-2">{props.subtitle}</h3>}
            {props.body.map((body,i)=>(
                <p 
                    className="standard-p" dangerouslySetInnerHTML={{__html:body}}
                    key={i+"-body"}
                />
            ))}
            {props.button &&
                <a className="basic-button w-max mt-5" href={props.button.url} target={props.button.target}>{props.button.button_copy}</a>
            } 
        </div>
        {props.media && 
        (props.media.type == 'image'
            ?
                <div className="w-full display grid grid-cols-1 grid-rows-1 justify-items-center content-center">
                    <img className={`w-full h-auto ${props.media.aspect_ratio ? `aspect-[${props.media.aspect_ratio}]` : 'aspect-video'} col-start-1 row-start-1`} src={props.media.src} alt={props.media.alt || "image"}/>
                </div>
            : props.media.type == 'video'
                ?
                <iframe
                    className="w-full h-auto aspect-video"
                    src={props.media.src}
                    title="Orbital"
                    aria-label="Orbital"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    referrerPolicy="strict-origin-when-cross-origin"
                    allowFullScreen
                    style={{
                    border: 0,
                    }}
                ></iframe>
                : ""
        )}
    </Section>
    )
}