import React from "react";
import BlocksGrid from "./BlocksGrid";
import FullWidthImage from "./FullWidthImage";
import Image from "./Image";
import RichText from "./RichText";

const Transformer = (props) => {
    const {type, data} = props
    const componentKey = {
        hero: FullWidthImage,
        richtext: RichText,
        grid: BlocksGrid,
        image: Image
    }
    if (typeof componentKey[type] != 'undefined') {
        return React.createElement(componentKey[type], {...data})
    }
}

export default Transformer

