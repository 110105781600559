import { createContext, useContext, useState, useRef, useEffect, useCallback } from "react";

// Create context
const ScrollContext = createContext({
    scrollTop: 0,
    isAboveThreshold: false,
});

// Custom hook to use scroll context
export const useScrollContext = () => useContext(ScrollContext);

// Provider component to wrap the children with scroll context
export function ScrollProvider({ children, threshold = 50 }) {
    const scrollableDivRef = useRef(null);
    const [scrollTop, setScrollTop] = useState(0);
    const [isAboveThreshold, setIsAboveThreshold] = useState(false);

    const handleScroll = useCallback(() => {
        const currentScrollPosition = scrollableDivRef.current?.scrollTop;

        if (currentScrollPosition === undefined) return;

        // Update scrollTop state
        setScrollTop(currentScrollPosition);

        // Only update isAboveThreshold if threshold crossing occurs
        setIsAboveThreshold(currentScrollPosition >= threshold);
    }, [threshold]);

    useEffect(() => {
        const target = scrollableDivRef.current;
        if (target) {
            target.addEventListener("scroll", handleScroll);
            return () => target.removeEventListener("scroll", handleScroll);
        }
    }, [handleScroll]);

    return (
        <ScrollContext.Provider value={{ scrollTop, isAboveThreshold }}>
            <div className="page-wrapper" ref={scrollableDivRef}>
                {children}
            </div>
        </ScrollContext.Provider>
    );
}
