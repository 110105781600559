import { useParams, Navigate } from 'react-router-dom';
import caseStudies from '../data/work';
import Transformer from '../components/blocks/Transformer';
import Page from '../components/blocks/Page';

export default function Slug() {
  const { slug } = useParams();
  
  const work = caseStudies.find(item => item.path === `/work/${slug}`);
  
  if (!work) {
    // Redirect to 404 if no match is found
    return <Navigate to="/404" />;
  }
  
  return (
    <Page navPosition="absolute" background={work.background_color}>
        {work.page.map((item, index) => (
            <Transformer key={item.id || index} {...item} />
        ))} 
    </Page>
  );
};
