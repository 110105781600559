import React from 'react';
import { NavLink } from 'react-router-dom';
import Mg from "../icons/Mg";
import { useScrollContext } from '../context/ScrollContext';

export default function Nav({navPosition}){
    const { isAboveThreshold } = useScrollContext();
    return(
        <nav 
            className={`w-full text-white flex flex-row justify-between items-start gap-4 text-lg transition-all sticky top-0 z-20 ${isAboveThreshold ? 'backdrop-blur bg-transparent-black px-2 h-16' : 'py-5 px-10 h-16'}`}
            style={{
                position: navPosition ? navPosition : 'sticky'
            }}
        >
            <NavLink
                to="/"
                className={({isActive}) => `mg ${isActive ? 'mg-animation w-28 h-28' : 'w-16 h-16 hover:scale-110'} transition-all ${isAboveThreshold ? 'scale-[0.65]' : ''}`}
            >
                <Mg/>
            </NavLink>
            
            <div className={`flex flex-row gap-2 mt-2 transition-all ${isAboveThreshold ? 'scale-75' : ''}`}>
                <NavLink
                    to="/work"
                    className={({isActive}) => `p-2 hover:scale-110 transition-all ${isActive ? 'font-bold' : ''}`}
                >
                    Work
                </NavLink>
                <NavLink
                    to="/about"
                    className={({isActive}) => `p-2 hover:scale-110 transition-all ${isActive ? 'font-bold' : ''}`}
                >
                    About
                </NavLink>
            </div>
        </nav>
    )
}