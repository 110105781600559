import { useEffect, useState, useRef } from "react";
import PhysicsExp from "../components/threejs/PhysicsExp";
import Portal from "../components/threejs/Portal";
import { Canvas } from '@react-three/fiber'
import GalaxyExp from "../components/threejs/GalaxyExp";
import Waves from "../components/threejs/Waves";
import Shuffle from "../components/icons/Shuffle";
import Page from "../components/blocks/Page";
import Section from "../components/blocks/Section";

export default function Home()
{

    //mouse position
    const [mousePosition,setMousePosition] = useState({ x: 1, y: 1 });
    const updateMousePosition = ev => {
        let fromCenterX = ev.clientX/window.innerWidth
        let fromCenterY = ev.clientY/window.innerHeight
        setMousePosition({ x: fromCenterX, y: fromCenterY });
    };
    useEffect(()=> {
        window.addEventListener('mousemove', updateMousePosition);
    })

    //window size
    const [windowSize, setWindowSize] = useState({x:window.innerWidth, y:window.innerHeight})
    const updateSize = () => {
        setWindowSize({x:window.innerWidth, y:window.innerHeight})
    }
    window.addEventListener('resize', updateSize)

    //set experience
    const allExperiences = ['portal', 'physics', 'galaxy', 'waves']

    const [experiences, setExperiences] = useState(allExperiences)
    const [whichExperience, setWhichExperience] = useState(experiences[(Math.floor(Math.random() * experiences.length))])

    //shuffles through experiences refreshing when they run out
    const shuffleExperience = () => {
        const expCopy = 
        experiences.length === 1
            ? allExperiences.filter(exp => exp != whichExperience)
            : experiences.filter(exp => exp != whichExperience)
       
        setWhichExperience(expCopy[(Math.floor(Math.random() * expCopy.length))])
        setExperiences(expCopy)
    }

    //Shuffle button
    const shuffleBtn = useRef() 
    const shuffle=()=> {
        shuffleExperience()
        shuffleBtn.current.classList.add('rotate')
        setTimeout(() => {
            shuffleBtn.current.classList.remove('rotate')
        }, 1500);
    }

    return <>
        <div className="absolute top-0 left-0 w-screen h-screen z-10">
            <Page>
                <Section className="flex-col mb-5 mt-20 md:mt-10 ">
                    <h1 className="text-6xl sm:text-8xl">Hi, I'm Mel</h1>
                    <h2 className="text-3xl max-w-md m-2">I'm a front end developer who enjoys finding creative solutions.</h2>
                    <div className="shuffle flex flex-row items-center justify-start w-max p-2 gap-3 cursor-pointer" onClick={shuffle}>
                        <div ref={shuffleBtn} className="w-5 h-5">
                            <Shuffle />
                        </div>
                        <span className="caps">Shuffle experience</span>
                    </div>
                </Section>
            </Page>
        </div>
        <div className="absolute top-0 left-0 w-screen h-screen z-0">
            {whichExperience === 'waves' &&
                <Canvas
                    camera={{
                        fov: 9,
                        near: 0.1,
                        far: 200,
                        position: [ 2, 25, 15 ]
                    }}
                >
                    <Waves
                        mousePosition={mousePosition}
                        windowSize={windowSize}
                    />
                </Canvas>
            }
            {whichExperience === 'portal' &&
                <Canvas>
                    <Portal 
                        mousePosition={mousePosition}
                        windowSize={windowSize}
                    /> 
                </Canvas>
            }
            {whichExperience === 'physics' &&
                <Canvas
                camera={{
                    fov: 40,
                    near: 0.1,
                    far: 200,
                    position: [ 0, 0, 9 ]
                }}>
                    <PhysicsExp 
                        mousePosition={mousePosition}
                    />
                </Canvas>
            }
            {whichExperience === 'galaxy' &&
                <Canvas>
                    <GalaxyExp 
                        mousePosition={mousePosition}
                    />
                </Canvas>
                
            }
        </div>
    </>
}