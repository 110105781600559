export default function Arrow(){
    return(
        <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="15" height="15"
        viewBox="0 0 224.5 358.7">
        <path fill="white" d="M190.5,358.7c-9.1,0-16.6-3.6-23.1-10c-21.3-21.4-42.7-42.8-64.1-64.2C71,252.1,38.7,219.7,6.3,187.2
		c-1.2-1.2-2.4-2.3-3.5-3.5c-3.7-3.8-3.7-5-0.2-8.7c0.3-0.4,0.7-0.7,1-1.1C57.7,119.8,111.8,65.6,165.9,11.4
		c5.2-5.2,10.8-9.1,18.1-10.7c17.7-3.8,35.8,7.8,39.6,25.6c2.7,12.4-1,22.8-9.9,31.7c-22.8,22.7-45.6,45.6-68.3,68.4
		c-14.6,14.6-29.1,29.2-43.6,43.7c-0.8,0.8-1.7,1.6-2.4,2.5c-4,4.6-4,9.2,0.3,13.7c5.7,5.9,11.5,11.6,17.2,17.3
		c32.3,32.3,64.5,64.6,96.8,96.9c9.8,9.8,13.3,21.1,9.2,34.6C218.5,349,205.2,358.7,190.5,358.7L190.5,358.7z"/>
        </svg>
    )
}