import ContactIcon from "../icons/ContactIcon";
import ResumeIcon from "../icons/ResumeIcon";

export default function Footer(){
    return(
        <footer className="w-full text-white flex flex-row justify-end items-center gap-4 py-6 px-10 text-lg">
            <a href="/melissa-gabriele-resume.pdf" target="_blank" className="w-7 h-6.5 m-2 hover:scale-110 transition-all"><ResumeIcon/></a>
            <a href="mailto:themgdesign@gmail.com" className="w-7 h-7 m-2 hover:scale-110 transition-all"><ContactIcon/></a>
        </footer>
    )
}