export default function FullWidthImage(props){

    return(
        <div 
            className={`w-full grid grid-rows-[1fr] grid-columns-[1fr] items-end overflow-hidden min-h-[250px] ${props.className ? props.className : ''}`}
            style={{
                height: props.img_height || '300px'
            }}
        >
            <div 
                className={`w-[100%] h-[130%] bg-no-repeat bg-scroll bg-cover ${props.position ? `bg-[${props.position}]` : 'bg-right-bottom'} z-0 transition-all brightness-90 col-start-1 row-start-1 brightness-50 opacity-75`}
                style={{ backgroundImage: `url(${props.img})` }}
            ></div>
            {props.title &&
                <h1 className="small-h1 text-center w-full mb-8 col-start-1 row-start-1 z-10 px-10"dangerouslySetInnerHTML={{__html:props.title}}/>
            }
        </div>
    )
}