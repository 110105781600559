import ReactDOM from 'react-dom/client'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import About from './pages/About';
import Work from './pages/Work';
import Slug from './pages/Slug';
import NotFound from './pages/404';

const root = ReactDOM.createRoot(document.querySelector('#root'));

root.render(
  <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/work" element={<Work />} /> 
        <Route path="/work/:slug" element={<Slug/>} />
        <Route path="/about" element={<About />} /> 
        <Route path="*" element={<NotFound/>} />
      </Routes>
  </Router>
);