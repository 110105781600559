export default function ResumeIcon(){
    return(
        <svg className="icon w-full h-full" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28.8 36.7">
            <path fill="#ffffff" strokeWidth="0" d="m27.2,5.5l-4.6-4.2C21.7.5,20.5,0,19.3,0H4.9C2.2-.1,0,2.1,0,4.8v27c0,2.7,2.2,4.9,4.9,4.9h19c2.7,0,4.9-2.2,4.9-4.9V9c0-1.3-.6-2.6-1.6-3.5Zm-8.4-2.3c0-.5.3-.8.7-1,.2-.1.3-.1.5-.1.3,0,.5.1.8.3l5.7,5.4c.5.5.4,1.1.3,1.2-.1.2-.3.7-1,.7h-5.9c-.6,0-1.1-.5-1.1-1.1V3.2Zm5.1,31.7H4.9c-1.7,0-3.1-1.4-3.1-3.1V4.8c0-1.7,1.4-3.1,3.1-3.1h12.5c-.3.4-.4.9-.4,1.5v5.4c0,1.6,1.3,2.9,2.9,2.9h5.9c.4,0,.9-.1,1.2-.3v20.6c0,1.7-1.4,3.1-3.1,3.1Z"/>
            <path fill="#ffffff" strokeWidth="0" d="m9.9,11.7c1.6,0,2.9-1.3,2.9-2.9s-1.3-2.9-2.9-2.9-2.9,1.3-2.9,2.9,1.3,2.9,2.9,2.9Zm0-4c.6,0,1.1.5,1.1,1.1s-.5,1.1-1.1,1.1-1.1-.5-1.1-1.1.5-1.1,1.1-1.1Z"/>
            <path fill="#ffffff" strokeWidth="0" d="m6.4,17.7c.5,0,.9-.4.9-.9,0-1.2,1.2-2.1,2.6-2.1s2.6,1,2.6,2.1c0,.5.4.9.9.9s.9-.4.9-.9c0-2.1-2-3.9-4.4-3.9s-4.4,1.8-4.4,3.9c0,.5.4.9.9.9Z"/>
            <path fill="#ffffff" strokeWidth="0" d="m22.9,21.9H5.9c-.5,0-.9.4-.9.9s.4.9.9.9h17c.5,0,.9-.4.9-.9s-.4-.9-.9-.9Z"/>
            <path fill="#ffffff" strokeWidth="0" d="m22.9,26.9H5.9c-.5,0-.9.4-.9.9s.4.9.9.9h17c.5,0,.9-.4.9-.9s-.4-.9-.9-.9Z"/>
        </svg>
    )
}

