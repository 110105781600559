import HalfImage from "../components/blocks/HalfImage";
import Page from "../components/blocks/Page";
import Section from "../components/blocks/Section";

export default function About() {
    return( 
        <Page>
            <Section className="flex-col md:flex-row" padding="px-0 md:px-10">
                <HalfImage image="/headshot.jpg"/>
                <Section className="flex-col backdrop-blur bg-transparent-black md:bg-transparent" padding="px-8 py-10 xs:px-12 sm:px-10">
                    <h1 className="standard-h1">About me</h1>
                    <p className="standard-p mt-1 mb-3 max-w-lg">I'm Mel, a front-end developer in Philadelphia focused on delivering user-friendly digital experiences that are seamless and delightful. With expertise in custom branding and full-scale website development, I specialize in turning creative ideas into functional solutions. I currently work with MOD on a diverse range of projects with clients like Comcast, Xfinity, CVS, and Aetna, where I build and maintain responsive websites and digital experiences. Leveraging my expertise in both design and development, I provide end-to-end branding and digital solutions for businesses.</p>
                    <h2 className="standard-h2">Interested in working together?</h2>
                    <a href="mailto:themgdesign@gmail.com" className="basic-button text-center">Contact Me</a>
                </Section>
            </Section>
        </Page>
    )
}