import Section from "./Section";

export default function HalfImage({image}){
    return(<>
        <div className="absolute w-full h-[50vh] min-h-[500px] md:w-1/2 md:h-screen top-0 left-0 z-0">
            <div className={`w-full h-full bg-no-repeat bg-scroll bg-cover object-contain bg-[top_center]`}
            style={{backgroundImage: `url('${image}')`}}
            ></div>
        </div>
        <Section className="h-[40vh] min-h-[400px] md:min-h-0 md:h-auto"></Section>
     </>)
}