import Section from "./Section";

const Media = ({ position, blur, center, image, video, bgColor, url})=>{
    return(
        image
        ?
        <div 
            className={`w-full h-full bg-no-repeat bg-scroll bg-cover ${position ? position === "center" ? "bg-center" : `bg-[${position}]` : "bg-[top_center]"} col-start-1 row-start-1 ${url ?`group-hover:scale-110 group-hover:opacity-85` : ''} ${url ? blur ? blur : center ? 'group-hover:blur-[1px]' : 'blur-[1px] group-hover:blur-[3px]' : ''} z-0 transition-all brightness-90`}
            style={{
                backgroundImage: image ? `url(${image})` : '',
                backgroundColor: bgColor ? bgColor : ''
            }}
        ></div>
        :
        <div className="w-full h-full">
        <video
            className="w-full h-full"
            autoPlay
            muted
            loop
            playsInline
            preload="auto"
            loading="lazy"
            width="1920"
            height="1080"
            >
            <source src={video} />
            <p>Your browser does not support the video tag.</p>
        </video>
        </div>

    )
}

const GridItem = ({props, center, outline}) => {
    return(
        props.url
        ?
        <a href={props.url} target={props.target === "self" ? "_self" : "_blank"} className={`min-h-[325px] w-full max-w-[900px] cursor-pointer grid ${center ?  outline ? 'grid-rows-2 md:w-[45%] rounded-xl bg-transparent border border-white' : 'grid-rows-2 md:w-[45%] rounded-xl bg-dark-grey border-[10px] border-dark-grey shadow-dark' : 'lg:w-[45%] grid-rows-[1fr]'} grid-columns-[1fr] items-center group overflow-hidden`}>
            <Media 
                position={props.position}
                blur={props.blur}
                center={center}
                image={props.image}
                video={props.video}
                bgColor={props.bg}
                url={props.url ? true : false}
            />
            <Section className={`col-start-1 ${center ? outline ? 'row-start-2 items-center text-center p-5 h-full' : 'row-start-2 items-center text-center backdrop-blur-xl xssm:backdrop-blur-lg p-5 h-full bg-dark-grey' : 'row-start-1 bg-more-transparent-black xssm:bg-transparent xssm:bg-black-gradient py-5'} z-10 h-full flex-col justify-center light`}>
                <h2 className={`standard-h2 ${center ? '' : 'sm:max-w-[60%]' }`} dangerouslySetInnerHTML={{__html:props.title}}/>
                <p className={`${center ? '' :'xssm:max-w-[60%]'} text-sm tracking-wide`} dangerouslySetInnerHTML={{__html: props.copy}}/>
                <button className={`basic-button w-max text-base group-hover:scale-100 border-[1px] border-white ${center ? 'text-white bg-transparent group-hover:bg-white group-hover:text-black' : 'group-hover:bg-transparent group-hover:text-white sm:max-w-[60%]'}`} >{props.buttontext}</button>
            </Section>
        </a>
        :
        <div className={`min-h-[325px] w-full max-w-[900px] grid ${center ?  outline ? 'grid-rows-2 md:w-[45%] border-white border-half rounded-lg' : 'grid-rows-2 md:w-[45%] rounded-xl bg-dark-grey border-[10px] border-dark-grey shadow-dark' : 'lg:w-[45%] grid-rows-[1fr]'} grid-columns-[1fr] items-center group overflow-hidden`}>
            <Media 
                position={props.position}
                blur={props.blur}
                center={center}
                image={props.image}
                video={props.video}
                bgColor={props.bg}
            />
            <Section className={`col-start-1 ${center ? outline ? 'row-start-2 items-center text-center p-5 h-full' : 'row-start-2 items-center text-center backdrop-blur-lg p-5 h-full bg-dark-grey' : 'row-start-1 bg-more-transparent-black xs:bg-transparent xs:bg-black-gradient py-5'} z-10 h-full flex-col justify-center light`}>
                <h2 className={`standard-h2 ${center ? '' : 'sm:max-w-[60%]' }`} dangerouslySetInnerHTML={{__html:props.title}}/>
                <p className={`${center ? '' :'xssm:max-w-[60%]'} text-sm tracking-wide`} dangerouslySetInnerHTML={{__html: props.copy}}/>
            </Section>
        </div>
    )
}

export default function BlocksGrid(props){
    return(
    <div className={`w-full flex flex-row flex-wrap align-center justify-center gap-7 p-7`}>
        {props.items.map((item, i) => {
            return <GridItem
                key={i}
                props={item}
                center={props.center}
                outline={props.outline}
            />
        })}
    </div>
    )
}